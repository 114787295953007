import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Layout, SliceZone } from '../components';

class Donate extends Component {
  render() {
    const {
      data: {
        page: {
          data: pageData,
        },
      },
      location,
    } = this.props;
    const {
      metaTitle,
      metaDescription,
      openGraphImage,
      body,
    } = pageData;
    const seoData = {
      metaTitle,
      metaDescription,
      openGraphImage,
    };
    return (
      <Layout location={location} seoData={seoData}>
        <SliceZone
          allSlices={body}
          location={location}
        />
      </Layout>
    );
  }
}

export default Donate;

export const pageQuery = graphql`
  query DonateQuery {
    site {
      siteMetadata {
        blogSlug,
      }
    },
    page: prismicDonate {
      uid,
      data {
        metaTitle: meta_title {
          html
          text
        },
        metaDescription: meta_description {
          html
          text
        },
        openGraphImage: open_graph_image {
          alt
          copyright
          url
        }
        body {
          ... on PrismicDonateBodyPageHero {
            id
            sliceType: slice_type
            primary {
              theme
              subtitle {
                text
              }
              headline {
                text
              }
              description {
                text
              }
              illustrationStyle: illustration_style
              illustrator {
                document {
                  ... on PrismicArtist {
                    data {
                      name {
                        text
                      }
                    }
                  }
                }
              }
              illustration {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
          }
          ... on PrismicDonateBodyImageTextBlock {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              photo {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              description {
                html
              },
              theme
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
                linkType: link_type
              }
            }
          }
          ... on PrismicDonateBodyCollapsibleContentBlock {
            id
            sliceType: slice_type
            primary {
              headline {
                text
              }
              subtitle {
                text
              }
              theme
            }
            items {
              sectionTitle: section_title {
                text
              }
              bodyContent: body_content {
                html
              }
            }
          }
          ... on PrismicDonateBodyContactFormBlock {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
            }
          }
          ... on PrismicDonateBodyTextHero {
            id
            sliceType: slice_type
            primary {
              theme
              headline {
                text
                html
              }
              description {
                text
                html
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicDonateBodyCallToActionTiles {
            id
            sliceType: slice_type
            primary {
              title {
                text
              }
              itemsPerRow: items_per_row
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicDonateBodyResourceTiles {
            id
            sliceType: slice_type
            primary {
              theme
            }
            items {
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
              title {
                text
                html
              }
              description {
                text
                html
              }
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
          ... on PrismicDonateBodyImageBlock {
            id
            sliceType: slice_type
            primary {
              theme
              image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_withWebp
                }
                alt
              }
            }
          }
          ... on PrismicDonateBodyVideoBlock {
            id
            sliceType: slice_type
            primary {
              theme
              videoProvider: video_provider
              videoUrl: video_url {
                url
              }
            }
            items {
              buttonLabel: button_label {
                text
              }
              buttonLink: button_link {
                url
              }
            }
          }
        }
      }
    }
  }
`;
